import { useResizeObserver } from "usehooks-ts";
import classNames from "classnames";
import { treemapData, toSize, unitCalculator } from "../../data/treemap";
import StepCounter from "../../components/stepCounter";
import React, { useEffect, useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import NextButton from "../../components/nextButton";
import { useDispatch, useSelector } from "react-redux";
import { setTreemapInput } from "../../utils/formSlice";

import { RootState } from "../../utils/store";

type onUpdateProps = (category: string, id: string, units: number) => void;

const breakpointPercentTotal = 3;
const breakpointPercentCategory = 3;

const SingleInput = ({
  data,
  onUpdate,
}: {
  data: any;
  onUpdate: onUpdateProps;
}) => {
  const onChange = (value: number) => {
    console.log(data);
    onUpdate(data.category, data.id, value);
  };
  return (
    <div className="flex flex-col justify-between">
      {data.image ? (
        <div className="grow">
          <img src={data.image} alt={data.name} />
        </div>
      ) : (
        <div className="grow"></div>
      )}
      <StepCounter
        title={data.name}
        tags={data.tags}
        defaultValue={data.units}
        onChange={onChange}
      />
    </div>
  );
};

const InputCollection = ({
  title,
  data,
  onUpdate,
}: {
  title: string;
  data: Array<any>;
  onUpdate: onUpdateProps;
}) => {
  return (
    <>
      <h4 className="sticky top-0 first:mt-0 mt-8 mb-2 text-gray-900 font-semibold bg-gray-100 rounded-sm px-4 py-2 z-50">
        {title}
      </h4>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-10 w-full">
        {data.map((d: any) => (
          <SingleInput data={d} onUpdate={onUpdate} />
        ))}
      </div>
    </>
  );
};

export function TreemapElement({
  name,
  tags,
  percentage,
  area,
  categoryPercentage,
  twBgColor,
  padding,
}: {
  name: string;
  percentage: number;
  area: number;
  categoryPercentage: number;
  twBgColor: string;
  padding: number;
  tags?: string[];
}) {
  return (
    <AnimatePresence>
      <motion.div
        layout
        transition={{ duration: 0.1, ease: "linear" }}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        className={classNames(
          "relative w-full min-h-0 gap-1",
          "flex flex-col items-center justify-center overflow-hidden p-2 rounded-sm"
        )}
        style={{
          flexBasis: `${categoryPercentage}%`,
          backgroundColor: `${twBgColor}`,
        }}
      >
        {categoryPercentage > breakpointPercentCategory &&
          percentage > breakpointPercentTotal && (
            <>
              <div>
                <p className="text-xs text-center m-0">{name}</p>
                {tags && tags.length > 0 && (
                  <p className="text-xs text-center m-0">
                    {"("}
                    {tags.join(", ")}
                    {")"}
                  </p>
                )}
              </div>
              <p className="text-xs text-center m-0">
                {Math.round(percentage)}%
              </p>
            </>
          )}
      </motion.div>
    </AnimatePresence>
  );
}

export function TreemapCategory({
  totalArea,
  category,
  data,
  percentage,
  padding,
}: {
  totalArea: number;
  category: string;
  data: any;
  percentage: number;
  padding: number;
}) {
  const size = toSize(totalArea);
  if (category === "hidden") {
    return (
      <AnimatePresence>
        <motion.div
          layout
          transition={{ duration: 0.1, ease: "linear" }}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          className={classNames("flex flex-col gap-1 overflow-hidden")}
          style={{ flexBasis: `${percentage}%` }}
        >
          {data.map((d: any) => {
            console.log(d);
            const area = d.area[size] * totalArea;
            return (
              <TreemapElement
                name={d.name}
                percentage={(100 * area) / totalArea}
                area={area}
                categoryPercentage={100}
                twBgColor={d.color}
                padding={padding}
              />
            );
          })}
        </motion.div>
      </AnimatePresence>
    );
  }
  const processedData = data.filter((d: any) => d.units > 0);
  const categoryAreaSum = processedData.reduce(
    (acc: number, cur: any) =>
      acc + cur.apu[size] * cur.units + (cur.extra ? cur.extra : 0),
    0
  );
  return (
    <AnimatePresence>
      <motion.div
        layout
        transition={{ duration: 0.1, ease: "linear" }}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        className={classNames("flex flex-col gap-1")}
        style={{ flexBasis: `${percentage}%` }}
      >
        {processedData.map((d: any) => {
          const area = d.units * d.apu[size] + (d.extra ? d.extra : 0);
          return (
            <TreemapElement
              name={d.name}
              tags={d.tags}
              percentage={(100 * area) / totalArea}
              area={area}
              categoryPercentage={(100 * area) / categoryAreaSum}
              twBgColor={d.color}
              padding={padding}
            />
          );
        })}
      </motion.div>
    </AnimatePresence>
  );
}

export function TreemapBuilder({
  data,
  keys,
  totalArea,
}: {
  data: any;
  keys: Array<string>;
  totalArea: number;
}) {
  const size = toSize(totalArea);
  const padding =
    data.hidden?.find((x: any) => x.id === "circulation")?.area[size] ?? 0;

  const getAreaSum = (d: Array<any>) =>
    d.reduce((acc, cur) => {
      console.log(cur);
      if (cur.show && cur.units > 0) {
        if (cur.category === "Open Workspaces")
          acc =
            acc +
            cur.units * cur.apu[size] * (1 + padding) +
            (cur.extra ? cur.extra : 0);
        else
          acc = acc + cur.units * cur.apu[size] + (cur.extra ? cur.extra : 0);
      }
      // else if (!cur.show)
      //   acc = acc + cur.area[size] * totalArea
      console.log(acc);
      return acc;
    }, 0);

  let occupiedArea = keys.reduce((acc, k) => acc + getAreaSum(data[k]), 0);

  keys = keys.filter((k) => getAreaSum(data[k]) > 0);

  const emptyArea = totalArea - occupiedArea;
  const emptyPercentage = (100 * emptyArea) / totalArea;

  return (
    <AnimatePresence>
      <motion.div
        layout
        transition={{ duration: 0.1, ease: "linear" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="treemap-main relative flex flex-row w-full h-full gap-1 rounded-sm overflow-hidden"
      >
        {keys
          .filter((k) => k !== "hidden")
          .map((k) => (
            <TreemapCategory
              totalArea={totalArea}
              category={k}
              data={data[k]}
              percentage={(100 * getAreaSum(data[k])) / totalArea}
              padding={k === "Open Workspaces" ? padding : 0}
            />
          ))}
        {emptyPercentage >= 1 && (
          <div
            className={classNames("flex flex-col")}
            style={{ flexBasis: `${emptyPercentage}%` }}
          >
            <TreemapElement
              name="Empty Area"
              percentage={emptyPercentage}
              area={emptyArea}
              categoryPercentage={100}
              twBgColor="#f7fafc"
              padding={0}
            />
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export function TreemapInfo({
  data,
  keys,
  totalArea,
  finalArea,
}: {
  data: any;
  keys: Array<string>;
  totalArea: number;
  finalArea: number;
}) {
  const size = toSize(totalArea);
  const additionalLegend = keys
    .filter((k) => k !== "hidden")
    .reduce((legend: Array<any>, k: string) => {
      const categoryData: Array<any> = data[k];
      const categoryAreaSum = categoryData.reduce(
        (acc: number, cur: any) =>
          acc + cur.apu[size] * cur.units + (cur.extra ? cur.extra : 0),
        0
      );
      const smallData: Array<any> = categoryData.filter(
        (d: any) =>
          d.units > 0 &&
          ((100 * (d.apu[size] * d.units + (d.extra ? d.extra : 0))) /
            categoryAreaSum <=
            breakpointPercentCategory ||
            (100 * (d.apu[size] * d.units + (d.extra ? d.extra : 0))) /
              totalArea <=
              breakpointPercentTotal)
      );
      legend = legend.concat(smallData);
      return legend;
    }, []);
  return (
    <div className="flex flex-row justify-between lg:flex-col lg:justify-start w-full gap-4">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
        {additionalLegend.map((l) => (
          <div key={l.id} className="flex flex-row gap-2 m-2">
            <div
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: `${l.color}` }}
            ></div>
            <p className="text-xs">{l.name}</p>
            <p className="text-xs">
              {Math.round(
                l.show
                  ? (100 * (l.units * l.apu[size] + (l.extra ? l.extra : 0))) /
                      totalArea
                  : 100 * l.area[size]
              )}
              %
            </p>
          </div>
        ))}
      </div>
      <div
        className={classNames(
          finalArea > totalArea && "text-red-500",
          "m-2 flex flex-col md:flex-row items-center lg:justify-around lg:mt-20"
        )}
      >
        <div className="text-center p-2 xl:p-4 min-w-[150px] lg:min-w-[200px] xl:min-w-[240px]">
          <p className="font-bold text-sm lg:text-md text-slate-800">
            Built Area
          </p>
          <p className="text-xl lg:text-2xl xl:text-3xl">{finalArea} sq ft.</p>
        </div>
        <div className="text-center p-2 xl:p-4 min-w-[150px] lg:min-w-[200px] xl:min-w-[240px]">
          <p className="font-bold text-sm lg:text-md text-slate-800">
            Available Area
          </p>
          <p className="text-xl lg:text-2xl xl:text-3xl">{totalArea} sq ft.</p>
        </div>
      </div>
    </div>
  );
}

export default function LayoutTreemap({
  goToPrevious,
  goToNext,
}: {
  goToPrevious?: () => void;
  goToNext: (data: any, callback?: () => void) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const { width = 0 } = useResizeObserver({ ref });

  const elementWidth = width > 600 ? 600 : width;
  const elementHeight = elementWidth / 2;

  console.log(elementHeight);

  const dispatch = useDispatch();
  const finalData = useSelector((state: RootState) => state.form.treemapInput);
  const setFinalData = (x: any) => {
    dispatch(setTreemapInput(x));
  };

  const [keys, setKeys] = useState<Array<string>>([]);
  const [finalArea, setFinalArea] = useState<number>(0);
  const totalArea = useSelector((state: RootState) =>
    state.form.carpetArea ? state.form.carpetArea : 2000
  );
  const size = toSize(totalArea);

  useEffect(() => {
    const data = unitCalculator(totalArea, treemapData);
    console.log(data);
    const processedData = data.reduce(function (result, iterator) {
      if (iterator.show) {
        result[iterator.category] = result[iterator.category] || [];
        result[iterator.category].push(iterator);
      } else {
        result["hidden"] = result["hidden"] || [];
        result["hidden"].push(iterator);
      }
      return result;
    }, Object.create(null));

    const padding =
      processedData?.hidden?.find((d: any) => d.id === "circulation")?.area[
        size
      ] ?? 0;

    setFinalData(processedData);
    setKeys(Object.keys(processedData));
    const finalAreaCalculation = Object.keys(processedData)
      .filter((k) => k !== "hidden")
      .reduce((area: number, k: string) => {
        return (
          area +
          (k === "Open Workspaces"
            ? processedData[k].reduce(
                (acc: number, cur: any) =>
                  acc +
                  cur.units * cur.apu[size] * (1 + padding) +
                  (cur.extra ? cur.extra : 0),
                0
              )
            : processedData[k].reduce(
                (acc: number, cur: any) =>
                  acc + cur.units * cur.apu[size] + (cur.extra ? cur.extra : 0),
                0
              ))
        );
      }, 0);
    setFinalArea(finalAreaCalculation);
  }, [totalArea, size]);

  const onUpdate: onUpdateProps = (category, id, units) => {
    console.log(category, id, units);
    const x = { ...finalData };
    console.log(x);
    const item = finalData[category].find((v: any) => v.id === id);
    console.log(item);
    const index = finalData[category].findIndex((v: any) => v.id === id);
    console.log(index);
    const catCopy = [...x[category]];
    catCopy[index] = { ...item, units: units };
    x[category] = catCopy;
    console.log(x);
    setFinalData(x);
  };

  useEffect(() => {
    const padding =
      finalData?.hidden?.find((d: any) => d.id === "circulation")?.area[size] ??
      0;
    const finalAreaCalculation = keys
      .filter((k) => k !== "hidden")
      .reduce((area: number, k: string) => {
        return (
          area +
          (k === "Open Workspaces"
            ? finalData[k].reduce(
                (acc: number, cur: any) =>
                  acc +
                  cur.units * cur.apu[size] * (1 + padding) +
                  (cur.extra ? cur.extra : 0),
                0
              )
            : finalData[k].reduce(
                (acc: number, cur: any) =>
                  acc + cur.units * cur.apu[size] + (cur.extra ? cur.extra : 0),
                0
              ))
        );
      }, 0);
    setFinalArea(finalAreaCalculation);
  }, [finalData, keys, size, totalArea]);

  const next = (callback: () => void) => {
    goToNext(finalData, callback);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="relative w-full h-full p-2 lg:p-10 lg:pb-4 flex flex-col"
      >
        <div
          ref={ref}
          className="relative w-full h-full flex flex-col lg:flex-row"
        >
          <div className="flex flex-col relative justify-start gap-4 lg:hidden">
            <div className="relative" style={{ height: `${elementHeight}px` }}>
              <TreemapBuilder
                data={finalData}
                totalArea={totalArea}
                keys={keys}
              />
              {finalArea > totalArea && (
                <div
                  className={classNames(
                    "absolute w-full border top-0 left-0 bg-red-500/70 rounded-sm"
                  )}
                  style={{ height: `${elementHeight}px` }}
                ></div>
              )}
            </div>
            <TreemapInfo
              data={finalData}
              totalArea={totalArea}
              keys={keys}
              finalArea={finalArea}
            />
          </div>
          <div className="hidden lg:basis-1/2 lg:flex flex-col relative lg:flex-1 justify-start gap-4 overflow-hidden">
            <div className="relative basis-1/2">
              <TreemapBuilder
                data={finalData}
                totalArea={totalArea}
                keys={keys}
              />
              {finalArea > totalArea && (
                <div
                  className={classNames(
                    "absolute w-full h-full border top-0 left-0 bg-red-500/70 rounded-sm"
                  )}
                ></div>
              )}
            </div>
            <TreemapInfo
              data={finalData}
              totalArea={totalArea}
              keys={keys}
              finalArea={finalArea}
            />
          </div>
          <div className="relative grow lg:basis-1/2 flex flex-col lg:flex-1 justify-start lg:px-4 mt-2 lg:mt-0 overflow-scroll no-scrollbar">
            {/* <div className="flex flex-col w-full gap-16 overflow-scroll">
          {keys.map(k => ())}
          </div> */}
            {keys
              .filter((k) => k !== "hidden")
              .map((k) => (
                <InputCollection
                  title={k}
                  data={finalData[k]}
                  onUpdate={onUpdate}
                />
              ))}
          </div>
        </div>
        {/* <div className="hidden lg:block relative flex-1 h-24 w-full px-20">
          <div className="w-full flex flex-row justify-end">
            <NextButton next={next} isNextActive={true} />
          </div>
        </div> */}
      </motion.div>
    </AnimatePresence>
  );
  //
}
